import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/analytics"; // Adicione esta linha para importar o módulo Analytics


const firebaseConfig = {
    apiKey: "AIzaSyAgg_ujDfp-_gB8dh6-pUn49J9vc2T186Q",
    authDomain: "pedrocunhawake.firebaseapp.com",
    projectId: "pedrocunhawake",
    storageBucket: "pedrocunhawake.appspot.com",
    messagingSenderId: "191161066610",
    appId: "1:191161066610:web:ee1efdf7447876e07e4eeb",
    measurementId: "G-DKD11Y4EEH"
  };
firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics(); // Inicialize o módulo Analytics

export const firebaseDb = firebase.firestore();
export const auth = firebase.auth();

export const imageStorage = firebase.storage();
export { analytics }; // Exporte o módulo Analytics
