import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import VueI18n from 'vue-i18n'
import ptBR from './locales/pt-BR.json'
import en from './locales/en.json'

import { analytics } from './firebaseConfig'; // Importe o módulo Analytics


Vue.use(CoolLightBox);
Vue.config.productionTip = false

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: store.state.language, // Idioma padrão
  fallbackLocale: 'pt-BR',
  messages: {
   'pt-BR': ptBR,
    'en':en,// Adicione mais idiomas conforme necessário
  }
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    this.$router.afterEach((to) => { // Remova ou comente a variável 'from' aqui
      // Rastrear a visualização da página com o Firebase Analytics
      analytics.setCurrentScreen(to.name || 'unknown_page');
      analytics.logEvent('page_view', { page_title: to.name || 'unknown_page' });
    });
  },
}).$mount('#app')
